export enum AppLayoutsEnum {
  default = "default",
  promo = "promo",
  payment= "payment",
}

export const AppLayoutToFileMap: Record<AppLayoutsEnum, string> = {
  default: "Default.vue",
  promo: "PromoLayouts.vue",
  payment: "PaymentLayout.vue",
}

