import { AppLayoutsEnum } from '@/layouts/layouts.types'
import { RouteRecordRaw } from "vue-router"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/payment/:domain/:subdomain/",
    name: "payment",
    component: () => import("./views/Payment.vue"),
    meta: {
      layout: AppLayoutsEnum.payment,
    }
  },
    {
    path: "/payment-summary/:domain/:subdomain/",
    name: "payment-summary",
    component: () => import("./views/PaymentSummary.vue"),
    meta: {
      layout: AppLayoutsEnum.payment,
    }
  },
]

export default routes
