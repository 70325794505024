import { createVfm } from 'vue-final-modal'

export const vfm = createVfm() as any

import { plugin, defaultConfig } from '@formkit/vue'
import config from './formkit.config'

import PrimeVue from 'primevue/config'
import router from '@/router'
import store from '@/store'

export default function install(Vue: any) {
  Vue.use(vfm)
  Vue.use(PrimeVue, {
    unstyled: true,
  })
  Vue.use(router)
  Vue.use(store)
  Vue.use(plugin, defaultConfig(config))
}
