import { createStore } from 'vuex'

export default createStore({
  state: {
    currencySign: '₽',
    acquiring: 35,
    tipsServiceCommission: 0.077,
    loyaltyType: null,
    loyaltySum: 0,
    user: null,
    items: [],
    userInfo: null,
    url: '',
    names: '',
    customer: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    },
    colorSchemeName: '',
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_COLOR_SCHEME_NAME(state, colorSchemeName) {
      state.colorSchemeName = colorSchemeName
    },
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo
    },
    SET_USER_URL(state, url) {
      state.url = url
    },
    SET_USER_NAMES(state, names) {
      state.names = names
    },
    SET_CUSTOMER(state, customer) {
      state.customer = customer
    },
    SET_LOYALTY_TYPE(state, loyaltyType) {
      state.loyaltyType = loyaltyType
    },
    SET_LOYALTY_SUM(state, loyaltySum) {
      state.loyaltySum = loyaltySum
    },
  },

  actions: {
    setCustomer({ commit }, customer) {
      commit('SET_CUSTOMER', customer)
    },
    setUser({ commit }, user) {
      commit('SET_USER', user)
    },
    setItems({ commit }, items) {
      commit('SET_ITEMS', items)
    },
    setUserInfo({ commit }, userInfo) {
      commit('SET_USER_INFO', userInfo)
    },
    setUserUrl({ commit }, url) {
      commit('SET_USER_URL', url)
    },
    setUserNames({ commit }, names) {
      commit('SET_USER_NAMES', names)
    },
    setLoyaltyType({ commit }, loyaltyType) {
      commit('SET_LOYALTY_TYPE', loyaltyType)
    },
    setLoyaltySum({ commit }, loyaltySum) {
      commit('SET_LOYALTY_SUM', loyaltySum)
    },
    SET_COLOR_SCHEME_NAME({ commit }, colorSchemeName) {
      commit('SET_COLOR_SCHEME_NAME', colorSchemeName)
    },
  },
  getters: {
    GET_USER(state) {
      return state.user
    },
    GET_ITEMS(state) {
      return state.items
    },
    GET_USER_INFO(state) {
      return state.userInfo
    },
    GET_USER_URL(state) {
      return state.url
    },
    GET_USER_NAMES(state) {
      return state.names
    },
    GET_CUSTOMER(state) {
      return state.customer
    },
    GET_CURRENCY_SIGN(state) {
      return state.currencySign
    },
    GET_ACQUIRING(state) {
      return state.acquiring
    },
    GET_TIPS_SERVICE_COMMISSION(state) {
      return state.tipsServiceCommission
    },
    GET_LOYALTY_TYPE(state) {
      return state.loyaltyType
    },
    GET_LOYALTY_SUM(state) {
      return state.loyaltySum
    },
    GET_COLOR_SCHEME_NAME(state) {
      return state.colorSchemeName
    },
  },
})
