import { createApp } from 'vue'
import App from './App.vue'
import FocusTrap from 'primevue/focustrap'
import install from '@/install'

import './styles/index.sass'
import './registerServiceWorker'

import 'vue-final-modal/style.css'
const app = createApp(App)
app.directive('focustrap', FocusTrap)
app.use(install).mount('#app')
